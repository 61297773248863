var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page"},[_c('h2',{staticClass:"accent--text"},[_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary","large":""}},[_vm._v("mdi-monitor-dashboard")]),_vm._v(" ادارة المناطق "),_c('back-button')],1),_c('hr',{staticClass:"my-5 header-divider"}),_c('div',{staticClass:"filter-box py-5 px-4 mb-6"},[_c('h3',{staticClass:"primary--text mb-3"},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-filter")]),_vm._v("تصنيف حسب ")],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"rounded":"","append-icon":"mdi-map","outlined":"","items":_vm.getCountries.map(function (ref) {
            var country_code = ref.country_code;
            var name = ref.name;

            return ({
              text: name,
              value: country_code,
            });
}),"label":"البلد"},on:{"change":function($event){return _vm.fetchCities(_vm.Country)}},model:{value:(_vm.Country),callback:function ($$v) {_vm.Country=$$v},expression:"Country"}}),_c('v-select',{attrs:{"rounded":"","append-icon":"mdi-map","disabled":!_vm.Country,"outlined":"","items":_vm.getCities.map(function (ref) {
                        var id = ref.id;
                        var name = ref.name;

                        return ({
              text: name,
              value: id,
            });
}),"label":"المحافظة"},on:{"change":_vm.changeSelected},model:{value:(_vm.getFilter.city_id),callback:function ($$v) {_vm.$set(_vm.getFilter, "city_id", $$v)},expression:"getFilter.city_id"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","solo":"","hide-details":"","clearable":"","label":"ابحث بالاسم   "},model:{value:(_vm.getFilter.search),callback:function ($$v) {_vm.$set(_vm.getFilter, "search", $$v)},expression:"getFilter.search"}}),_c('v-btn',{staticClass:"my-3",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.handleFilters('search')}}},[_vm._v("بحث")])],1),_c('v-col',{attrs:{"md":"2"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){_vm.getFilter.active = true;
            _vm.handleFilters('active')}}},[_vm._v(" المناطق المفعلة ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){_vm.getFilter.active = false;
            _vm.handleFilters('active')}}},[_vm._v(" المناطق الغير مفعلة ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"primary"},on:{"click":_vm.handleGetAll}},[_vm._v("عرض الكل ")])],1)],1)],1),_c('v-chip',{staticClass:"mb-2",attrs:{"right":"","label":"","color":"secondary"}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-map ")]),_vm._v(" عدد المناطق "+_vm._s(_vm.getTotal_areas))],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getAreasList,"loading-text":"جاري تحميل البيانات","no-data-text":"لا يوجد بيانات","hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.handleCheckboxChange(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"d-flex justify-end",attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" إضافة منطقة جديدة ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"rounded":"","append-icon":"mdi-map","outlined":"","items":_vm.getCountries.map(function (ref) {
                        var country_code = ref.country_code;
                        var name = ref.name;

                        return ({
                          text: name,
                          value: country_code,
                        });
            }),"label":"البلد"},on:{"change":function($event){return _vm.fetchCities(_vm.Country)}},model:{value:(_vm.Country),callback:function ($$v) {_vm.Country=$$v},expression:"Country"}}),_c('v-select',{attrs:{"rounded":"","disabled":!_vm.Country,"append-icon":"mdi-map","outlined":"","items":_vm.getCities.map(function (ref) {
                                    var id = ref.id;
                                    var name = ref.name;

                                    return ({
                          text: name,
                          value: id,
                        });
            }),"label":"المحافظة"},model:{value:(_vm.city_id),callback:function ($$v) {_vm.city_id=$$v},expression:"city_id"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"الاسم بالعربية"},model:{value:(_vm.name_ar),callback:function ($$v) {_vm.name_ar=$$v},expression:"name_ar"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"الاسم بالانجليزية"},model:{value:(_vm.name_en),callback:function ($$v) {_vm.name_en=$$v},expression:"name_en"}}),_c('v-text-field',{attrs:{"outlined":"","rounded":"","type":"number","label":"الترتيب"},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_vm._v(" الحالة : "),_c('v-switch',{staticClass:"mx-7",attrs:{"inset":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)],1)],1)],1),_c('hr',{staticClass:"header-divider primary"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"my-2 px-3 py-3",attrs:{"color":"blue darken-1","dark":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" حفظ ")]),_c('v-btn',{staticClass:"my-2 mx-2 px-3 py-3",attrs:{"color":"red darken-1","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" الغاء ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mx-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" إضافة محافظة جديدة ")])]}}]),model:{value:(_vm.citiesDialog),callback:function ($$v) {_vm.citiesDialog=$$v},expression:"citiesDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v(" إضافة محافظة جديدة")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'هذا الحقل مطلوب'; }],"rounded":"","append-icon":"mdi-map","outlined":"","items":_vm.getCountries.map(function (ref) {
                                    var id = ref.id;
                                    var name = ref.name;

                                    return ({
                          text: name,
                          value: id,
                        });
            }),"label":"البلد"},model:{value:(_vm.formCity.country_id),callback:function ($$v) {_vm.$set(_vm.formCity, "country_id", $$v)},expression:"formCity.country_id"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"الاسم بالعربية","rules":[function (v) { return !!v || 'هذا الحقل مطلوب'; }]},model:{value:(_vm.formCity.name_ar),callback:function ($$v) {_vm.$set(_vm.formCity, "name_ar", $$v)},expression:"formCity.name_ar"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"الاسم بالانجليزية","rules":[function (v) { return !!v || 'هذا الحقل مطلوب'; }]},model:{value:(_vm.formCity.name_en),callback:function ($$v) {_vm.$set(_vm.formCity, "name_en", $$v)},expression:"formCity.name_en"}}),_c('v-text-field',{attrs:{"outlined":"","rounded":"","type":"number","label":"الترتيب","rules":[function (v) { return !!v || 'هذا الحقل مطلوب'; }]},model:{value:(_vm.formCity.order),callback:function ($$v) {_vm.$set(_vm.formCity, "order", $$v)},expression:"formCity.order"}}),_vm._v(" الحالة : "),_c('v-switch',{staticClass:"mx-7",attrs:{"inset":""},model:{value:(_vm.formCity.active),callback:function ($$v) {_vm.$set(_vm.formCity, "active", $$v)},expression:"formCity.active"}})],1)],1)],1)],1),_c('hr',{staticClass:"header-divider primary"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"my-2 px-3 py-3",attrs:{"color":"blue darken-1","dark":""},on:{"click":function($event){return _vm.postCity(_vm.formCity);}}},[_vm._v(" حفظ ")]),_c('v-btn',{staticClass:"my-2 mx-2 px-3 py-3",attrs:{"color":"red darken-1","dark":""},on:{"click":function($event){_vm.citiesDialog = !_vm.citiesDialog}}},[_vm._v(" الغاء ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2 mx-2",attrs:{"color":"error","dark":""}},'v-btn',attrs,false),on),[_vm._v(" حذف محافظة ")])]}}]),model:{value:(_vm.actionDialog),callback:function ($$v) {_vm.actionDialog=$$v},expression:"actionDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v("مسح محافظة")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"rounded":"","append-icon":"mdi-map","outlined":"","items":_vm.getCountries.map(function (ref) {
                                    var country_code = ref.country_code;
                                    var name = ref.name;

                                    return ({
                          text: name,
                          value: country_code,
                        });
            }),"label":"البلد"},on:{"change":function($event){return _vm.fetchCities(_vm.countryAction)}},model:{value:(_vm.countryAction),callback:function ($$v) {_vm.countryAction=$$v},expression:"countryAction"}}),_c('v-select',{attrs:{"rounded":"","append-icon":"mdi-map","disabled":!_vm.countryAction,"outlined":"","items":_vm.getCities.map(function (ref) {
                                    var id = ref.id;
                                    var name = ref.name;

                                    return ({
                          text: name,
                          value: id,
                        });
            }),"label":"المحافظة"},model:{value:(_vm.cityAction),callback:function ($$v) {_vm.cityAction=$$v},expression:"cityAction"}})],1)],1)],1)],1),_c('hr',{staticClass:"header-divider primary"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"my-2 px-3 py-3",attrs:{"color":"red darken-1","dark":""},on:{"click":function($event){return _vm.deleteCityAction(_vm.cityAction)}}},[_vm._v(" مسح ")]),_c('v-btn',{staticClass:"my-2 mx-2 px-3 py-3",attrs:{"color":"blue darken-1","dark":""},on:{"click":function($event){_vm.actionDialog = !_vm.actionDialog}}},[_vm._v(" الغاء ")])],1)],1)],1),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteSelectedAreas}},[_vm._v("حذف المحدد")])],1)]},proxy:true},{key:"item.blocked",fn:function(ref){
                        var item = ref.item;
return [(item.active === 0)?_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.unblockUser(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}}):_vm._e(),(item.active === 1)?_c('v-switch',{attrs:{"inset":""},on:{"change":function($event){return _vm.blockUser(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}}):_vm._e()]}},{key:"item.city",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.city_name)+" ")]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.delete",fn:function(ref){
                        var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v(" حذف ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"red","dark":""}},[_vm._v("حذف منطقة")]),_c('v-card-text',[_c('h4',{staticClass:"my-3 red--text"},[_vm._v("هل تريد حذف هذه المنطقة")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.deleteThisRegion(item.id), (dialog.value = false)}}},[_vm._v(" حذف ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("الغاء")])],1)],1)]}}],null,true)})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"value":+_vm.getFilter.page,"circle":"","total-visible":"7","length":Number(_vm.getLastPage)},on:{"input":_vm.handlingRoute}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }