<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-monitor-dashboard</v-icon>
      ادارة المناطق
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />
    <div class="filter-box py-5 px-4 mb-6">
      <h3 class="primary--text mb-3">
        <v-icon class="ml-1">mdi-filter</v-icon>تصنيف حسب
      </h3>
      <v-row align="center">
        <!-- QUERY -->

        <!-- Q -->
        <v-col cols="12" md="6">
          <v-select
            v-model="Country"
            rounded
            append-icon="mdi-map"
            outlined
            @change="fetchCities(Country)"
            :items="
              getCountries.map(({ country_code, name }) => ({
                text: name,
                value: country_code,
              }))
            "
            label="البلد"
          >
          </v-select>
          <v-select
            v-model="getFilter.city_id"
            rounded
            append-icon="mdi-map"
            :disabled="!Country"
            outlined
            @change="changeSelected"
            :items="
              getCities.map(({ id, name }) => ({
                text: name,
                value: id,
              }))
            "
            label="المحافظة"
          >
          </v-select>
          <v-text-field
            rounded
            outlined
            solo
            hide-details
            clearable
            label="ابحث بالاسم   "
            v-model="getFilter.search"
          ></v-text-field>
          <v-btn class="my-3" @click="handleFilters('search')" large color="primary"
            >بحث</v-btn
          >
        </v-col>
        <v-col md="2"></v-col>
        <v-col cols="12" md="4">
          <v-btn
            class="my-2"
            @click="
              getFilter.active = true;
              handleFilters('active')
            "
            block
            large
            color="primary"
          >
            المناطق المفعلة
          </v-btn>
          <v-btn
            block
            class="my-2"
            @click="
              getFilter.active = false;
              handleFilters('active')
            "
            large
            color="primary"
          >
            المناطق الغير مفعلة
          </v-btn>
          <v-btn
            class="my-2"
            block
            @click="
              handleGetAll
            "
            large
            color="primary"
            >عرض الكل
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-chip right class="mb-2" label color="secondary">
      <v-icon class="mx-2"> mdi-map </v-icon> عدد المناطق
      {{ getTotal_areas }}</v-chip
    >
    <!-- TABLE -->
    <v-data-table
      :headers="headers"
      :items="getAreasList"
      loading-text="جاري تحميل البيانات"
      no-data-text="لا يوجد بيانات"
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
      class="elevation-1"
    >
      <template v-slot:item.checkbox="{ item }">
        <v-checkbox v-model="item.selected" @change="handleCheckboxChange(item)"></v-checkbox>
      </template>

      <template v-slot:top>
        <v-toolbar flat class="d-flex justify-end">
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="my-2" v-bind="attrs" v-on="on">
                إضافة منطقة جديدة
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="Country"
                        rounded
                        append-icon="mdi-map"
                        outlined
                        @change="fetchCities(Country)"
                        :items="
                          getCountries.map(({ country_code, name }) => ({
                            text: name,
                            value: country_code,
                          }))
                        "
                        label="البلد"
                      >
                      </v-select>
                      <v-select
                        v-model="city_id"
                        rounded
                        :disabled="!Country"
                        append-icon="mdi-map"
                        outlined
                        :items="
                          getCities.map(({ id, name }) => ({
                            text: name,
                            value: id,
                          }))
                        "
                        label="المحافظة"
                      >
                      </v-select>
                      <v-text-field
                        v-model="name_ar"
                        rounded
                        outlined
                        label="الاسم بالعربية"
                      ></v-text-field>
                      <v-text-field
                        v-model="name_en"
                        rounded
                        outlined
                        label="الاسم بالانجليزية"
                      ></v-text-field>
                      <v-text-field
                        v-model="order"
                        outlined
                        rounded
                        type="number"
                        label="الترتيب"
                      ></v-text-field>
                      الحالة :
                      <v-switch class="mx-7" inset v-model="active"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <hr class="header-divider primary" />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  dark
                  class="my-2 px-3 py-3"
                  @click="save()"
                >
                  حفظ
                </v-btn>

                <v-btn
                  color="red darken-1"
                  dark
                  class="my-2 mx-2 px-3 py-3"
                  @click="close()"
                >
                  الغاء
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="citiesDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="my-2 mx-2"
                v-bind="attrs"
                v-on="on"
              >
                إضافة محافظة جديدة
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="text-h5"> إضافة محافظة جديدة</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :rules="[(v) => !!v || 'هذا الحقل مطلوب']"
                        v-model="formCity.country_id"
                        rounded
                        append-icon="mdi-map"
                        outlined
                        :items="
                          getCountries.map(({ id, name }) => ({
                            text: name,
                            value: id,
                          }))
                        "
                        label="البلد"
                      >
                      </v-select>
                      <v-text-field
                        v-model="formCity.name_ar"
                        rounded
                        outlined
                        label="الاسم بالعربية"
                        :rules="[(v) => !!v || 'هذا الحقل مطلوب']"
                      ></v-text-field>
                      <v-text-field
                        v-model="formCity.name_en"
                        rounded
                        outlined
                        label="الاسم بالانجليزية"
                        :rules="[(v) => !!v || 'هذا الحقل مطلوب']"
                      ></v-text-field>
                      <v-text-field
                        v-model="formCity.order"
                        outlined
                        rounded
                        type="number"
                        label="الترتيب"
                        :rules="[(v) => !!v || 'هذا الحقل مطلوب']"
                      ></v-text-field>
                      الحالة :
                      <v-switch
                        class="mx-7"
                        inset
                        v-model="formCity.active"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <hr class="header-divider primary" />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  dark
                  class="my-2 px-3 py-3"
                  @click="
                    postCity(formCity);
                  "
                >
                  حفظ
                </v-btn>

                <v-btn
                  color="red darken-1"
                  dark
                  class="my-2 mx-2 px-3 py-3"
                  @click="citiesDialog = !citiesDialog"
                >
                  الغاء
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="actionDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                dark
                class="my-2 mx-2"
                v-bind="attrs"
                v-on="on"
              >
                حذف محافظة
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="text-h5">مسح محافظة</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="countryAction"
                        rounded
                        append-icon="mdi-map"
                        outlined
                        @change="fetchCities(countryAction)"
                        :items="
                          getCountries.map(({ country_code, name }) => ({
                            text: name,
                            value: country_code,
                          }))
                        "
                        label="البلد"
                      >
                      </v-select>
                      <v-select
                        v-model="cityAction"
                        rounded
                        append-icon="mdi-map"
                        :disabled="!countryAction"
                        outlined
                        :items="
                          getCities.map(({ id, name }) => ({
                            text: name,
                            value: id,
                          }))
                        "
                        label="المحافظة"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <hr class="header-divider primary" />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  dark
                  class="my-2 px-3 py-3"
                  @click="deleteCityAction(cityAction)"
                >
                  مسح
                </v-btn>

                <v-btn
                  color="blue darken-1"
                  dark
                  class="my-2 mx-2 px-3 py-3"
                  @click="actionDialog = !actionDialog"
                >
                  الغاء
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="error" @click="deleteSelectedAreas">حذف المحدد</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.blocked`]="{ item }">
        <v-switch
          v-if="item.active === 0"
          inset
          v-model="item.active"
          @change="unblockUser(item)"
        ></v-switch>

        <v-switch
          v-if="item.active === 1"
          inset
          v-model="item.active"
          @change="blockUser(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.city`]="{ item }">
        {{ item.city_name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-dialog transition="dialog-top-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on"> حذف </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="red" dark>حذف منطقة</v-toolbar>
              <v-card-text>
                <h4 class="my-3 red--text">هل تريد حذف هذه المنطقة</h4>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  color="red"
                  dark
                  @click="deleteThisRegion(item.id), (dialog.value = false)"
                >
                  حذف
                </v-btn>
                <v-btn text @click="dialog.value = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>
    <!-- PAGINATION -->
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                :value="+getFilter.page"
                circle
                total-visible="7"
                class="my-4"
                :length="Number(getLastPage)"
                @input="handlingRoute"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "areas",

  data: function () {
    return {
      countryAction: "",
      cityAction: "",
      actionDialog: false,
      formCity: {
        name_en: "",
        name_ar: "",
        country_id: "",
        order: "",
        active: "",
      },
      filter: {
        search: "",
        city_id: "",
        active: null,
      },
      Country: "",
      city_id: "",
      name_en: "",
      name_ar: "",
      order: "",
      active: false,
      // table
      isLoading: false,
      dialog: false,
      citiesDialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "حذف",
          value: "checkbox",
        },
        {
          value: "name_ar",
          text: "الاسم بالعربية",
        },
        {
          value: "name_en",
          text: "الاسم بالانجليزية",
        },
        {
          value: "city",
          text: "المدينة",
        },
        {
          value: "blocked",
          text: "تفعيل/تعطيل",
        },
        {
          value: "actions",
          text: "تعديل",
        },
        {
          text: "حذف",
          value: "delete",
        },
      ],
      editedIndex: -1,
    };
  },

  computed: {
    ...mapGetters("Areas", [
      "getAreasList",
      "getTotal_areas",
      "getCities",
      "getCountries",
      "getFilter",
      "getLastPage",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "إضافة منطقة" : "تعديل المنطقة";
    },
  },
  methods: {
    ...mapMutations("Areas", ["setFilter"]),
    ...mapActions("Areas", [
      "fetchAreas",
      "postArea",
      "updateArea",
      "fetchCountries",
      "fetchCities",
      "deleteArea",
      "postCity",
      "deleteCity","multiDeleteAreas"
    ]),
    editItem(item) {
      this.editedIndex = 500;
      this.id = item.id;
      this.name_en = item.name_en;
      this.name_ar = item.name_ar;
      this.city_id = item.city_id;
      this.order = item.order;
      this.active = item.active;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.name_en = "";
      this.name_ar = "";
      this.city_id = "";
      this.order = "";
      this.active = 0;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.editRegion();
        this.$store
          .dispatch("Areas/fetchAreas")
          .then(() => {
            this.loading = false;
          });
      } else {
        this.addRegion();
        this.$store
          .dispatch("Areas/fetchAreas")
          .then(() => {
            this.loading = false;
          });
      }
      this.close();
    },
    handleFilters(val){
       this.setFilter({...this.$route.query , [val] : this.getFilter[val] , page : 1}); 
       this.$router.replace({query:{...this.$route.query , page:1} });
       if(this.$route.query.page == 1){
        this.$store
          .dispatch("Areas/fetchAreas")
          .then(() => {
            this.loading = false;
          });
        }
     },
     handleGetAll(){
      this.setFilter({...this.$router.query, page:1})
      this.filter={active:null , search : '' , sub_category_id :''}
      this.$router.replace({query:{}})
     },
    deleteCityAction(id) {
      swal({
        title: "هل انت متاكد ؟",
        icon: "warning",
        buttons: true,
        buttons: ["تراجع", "نعم، احذف"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteCity(id);
          this.fetchCities(this.countryAction);
          this.countryAction = "";
          this.cityAction = "";
        } else {
          swal("لم يتم المسح");
        }
      });
    },
    addCity() {
      this.postCity({ item: this.formCity });
      this.fetchCountries();
    },
    addRegion() {
      this.postArea({
        name_en: this.name_en,
        name_ar: this.name_ar,
        city_id: this.city_id,
        order: this.order,
        active: this.active,
      }).then(() => {
        this.fetchAreas(this.filter);
      });
    },
    editRegion() {
      this.updateArea({
        id: this.id,
        name_en: this.name_en,
        name_ar: this.name_ar,
        city_id: this.city_id,
        order: this.order,
        active: this.active,
      }).then(() => {
        this.fetchAreas(this.filter);
      });
    },

    blockUser(item) {
      this.updateArea({
        id: item.id,
        name_en: item.name_en,
        name_ar: item.name_ar,
        city_id: item.city_id,
        order: item.order,
        active: 0,
      }).then(() => {
        this.fetchAreas(this.filter);
      });
    },
    unblockUser(item) {
      this.updateArea({
        id: item.id,
        name_en: item.name_en,
        name_ar: item.name_ar,
        city_id: item.city_id,
        order: item.order,
        active: 1,
      }).then(() => {
        this.fetchAreas(this.filter);
      });
    },
    deleteThisRegion(id) {
      this.deleteArea(id).then(() => {
        this.fetchAreas(this.filter);
      });
    },
    deleteSelectedAreas() {

      const selectedAreas = this.getAreasList.filter((item) => item.selected);
      const areasIds = selectedAreas.map((item) => item.id);

      if (areasIds.length > 0) {
        this.multiDeleteAreas(areasIds).then(() => {
          this.fetchAreas();
        });
      } else {
        // Show a message indicating no areas are selected
        // You can use a snackbar or any other UI component for this
        console.log('No Areas selected for deletion.');
      }
    },
    handleCheckboxChange(item) {
      // Implement your logic here
      console.log('Checkbox changed for item:', item);
    },
    handlingRoute(val) {
      this.$router
        .replace({
          query: { ...this.$route.query, page: val },
        })
        .catch(() => {});
    },
    changeSelected(val) {
      this.setFilter({...this.$router.query, page:1}) // to remoce data in search if we change category
      this.$router.push({query:
         {
            ...this.$route.query,
            page: 1,
            city_id: val,
          },
        })
        .catch(() => {});

    },
  },
  mounted() {
      this.fetchCountries();
  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({
            query: { ...route.query, page: 1 },
          });
          return;
        }
        this.setFilter({ ...this.getFilter,...route.query });
        this.$store
          .dispatch("Areas/fetchAreas")
          .then(() => {
            this.loading = false;
          });
      },
      deep: true,
      immediate: true,
    },
    dialog(val) {
      if (!val) {
        this.city_id = "";
        this.CityId = "";
        this.RegionId = "";
      }
    },
  },
};
</script>
